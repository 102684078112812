import { mergeClasses } from '@expo/styleguide';
import { File05Icon } from '@expo/styleguide-icons/outline/File05Icon';
import { PropsWithChildren } from 'react';

import { Snippet } from '~/ui/components/Snippet/Snippet';
import { SnippetContent } from '~/ui/components/Snippet/SnippetContent';
import { SnippetHeader } from '~/ui/components/Snippet/SnippetHeader';
import { CopyAction } from '~/ui/components/Snippet/actions/CopyAction';

import { convertChildrenToString } from '../helpers';

type Props = PropsWithChildren<{
  title?: string;
  className?: string;
}>;

export function CodeBlock({ children, title, className }: Props) {
  return (
    <Snippet className={mergeClasses('mb-4 mt-3', className)}>
      {title && (
        <SnippetHeader title={title} Icon={File05Icon}>
          <CopyAction text={convertChildrenToString(children)} />
        </SnippetHeader>
      )}
      <SnippetContent
        className={mergeClasses(
          'bg-transparent p-0',
          '[&_pre]:!my-0 [&_pre]:rounded-none [&_pre]:border-0 [&_pre]:!py-2.5'
        )}>
        {children}
      </SnippetContent>
    </Snippet>
  );
}
