import { Children, isValidElement, type ReactElement, type ReactNode } from 'react';

export function convertChildrenToString(children: ReactNode): string {
  if (isValidElement(children)) {
    return convertChildrenToString(children.props.children);
  }
  if (!Array.isArray(children)) {
    return children?.toString() ?? '';
  }
  return Children.map(children, (child) => {
    if (typeof child === 'string') {
      return child;
    } else if (isValidElement(child)) {
      const elementChild = child as ReactElement;
      return convertChildrenToString(elementChild.props.children);
    }
    return '';
  }).join('');
}
